

function updateTopbar() {
	// var $bg = $("#background_picture");
	// var $logo = $("#logo");
	// var waypoint = parseInt(($bg.offset()).top) + parseInt($bg.height());
	// var cur = parseInt(($logo.offset()).top) + parseInt($logo.height()) + 0;
	var waypoint = 100;
	var cur = parseInt($( window ).scrollTop());
	$("body").toggleClass('scrolled', (waypoint < cur));
}


function toggleSignupAddress(o) {
	var $o = $( o );
	$o.parent().siblings('.frmField').toggleClass('hide', !$o[0].checked );
	$o.parent().parent().find('input,textarea,button,select').not( $o ).attr('disabled', !$o[0].checked);
}

function doSignup(args) {
	$('#Frm').submit();
}


$( document ).ready(function(){

	// Mobile menu toggle
	$('#menu-inner')
		.find('> a.menu-toggle')
			.on('click', function(e){
				e.preventDefault();
				e.stopImmediatePropagation();
				$('#menu-inner').toggleClass('open');
			})
		.end()
		.on('click', function(e){
			$('#menu-inner').removeClass('open');
		});

	$( window ).on('scroll resize', updateTopbar);


	// Disable hidden form elements
	$('.frmField.hide').find('input,textarea,button,select').attr('disabled', true);

	$('#go_mod_contact').on('click', function(e){
		e.preventDefault();
		e.stopImmediatePropagation();
		$('#do_mod_contact').click();
	});

	
});